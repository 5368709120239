.popup{
    background-color: rgba(0, 0, 0, 0.6);
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    position: fixed;
    top: 0;
    display: none;
    justify-content: center ;
    z-index: 10;

}
.popup_content{
    background-color: #fff;
    width: 30%;
    position: fixed;
    border-radius: 10px;
    top: 10%;
    min-width: 400px;
    width: 37%;
}
.form-row {
    display:flex;
    width: 100%;
    align-items: center !important;
    justify-content: center !important;
    margin-bottom: 10px; /* Optional margin between rows */
}
.form-row a{
    position:relative;
    align-items: center;
    border-radius: 5px;
    left: 40%;
}
 
.form_popup input{
 margin: 10px auto;
 display: block;
 border: 1px solid rgb(197, 221, 226);
 padding: 5px;
 width: 90%;
 outline: none;
}
.form_popup label{
    padding-left: 10%;
}
.close{
    top: -16px;
    right: -16px;
    position: absolute;
    color: rgb(215, 209, 209);
    font-size: 25px;
    cursor: pointer;
}
.title{
    text-align: center;
    padding-top: 20px;
    background-color: #0e4295;
    padding-bottom: 20px;
    color: #fff;
    border-radius: 0px;
    width: 100%;
}
.submit{
   width: 100px;
   background-color: #0e4295;
   color:#fff;
   justify-content: center;
   display: flex;
   margin: auto;
   text-align: center;
   margin-top: 8%;
   margin-bottom: 8%;
   padding: 10px 10px;
   border-radius: 5px;
}
.close{
    width: 100px;
    display: flex;
    position: relative;
    float: left;
    margin: auto;
    text-align: center;
    margin-top: 8%;
    margin-bottom: 8%;
    padding: 0px 10px;
 }


.errors{
    color: red;
    margin-bottom: 0px !important;
    padding-left: 10%;
    display: flex;
}
.select_language option{
position: relative;
top: 10px;

}
