/*
=====
CORE STYLES
=====
*/

.mask {
  --uiMaskClipPath: var(--maskClipPath);

  box-sizing: var(--maskBoxSizing, border-box);
  display: var(--maskDisplay, inline-flex);
  padding: var(--maskStrokeThickness, 3px);
  clip-path: var(--uiMaskClipPath);
  /* background-image: linear-gradient(134deg, #0c0091 0%, #00a87e 100%); */
  /* background-color: var(--maskStrokeColor, currentColor); */
}

.mask__img {
  max-width: 100%;
  display: block;
  clip-path: var(--uiMaskClipPath);
}

.mask,
.mask__img {
  transition: clip-path var(--maskAnimationDuration, 0.2s)
    var(--maskAnimationTimingFunction, ease-out);
}

/* masks */

.mask_type2 {
  --maskClipPath: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

.mask_type3 {
  --maskClipPath: polygon(
    0 10%,
    10% 10%,
    10% 0,
    90% 0,
    90% 10%,
    100% 10%,
    100% 90%,
    90% 90%,
    90% 100%,
    10% 100%,
    10% 90%,
    0 90%
  );
}

.mask_type4 {
  --maskClipPath: polygon(0 50%, 50% 0, 100% 50%, 50% 100%);
}

/* effect 1*/

.mask_type1 {
  --maskClipPath: polygon(
    0 0,
    100% 0,
    100% 10%,
    100% 100%,
    90% 100%,
    0% 100%,
    0% 90%,
    0% 10%
  );
}

/* effect 2*/

.mask_type2-a1 {
  --maskAnimationDuration: 0.4s;
}

/* effect 3*/

.mask_type3-a1 {
  --maskAnimationDuration: 0.5s;
}

/* effect 4*/

/* .mask_type4:hover,
.mask_type4:focus {
  --maskClipPath: polygon(0 0, 100% 0, 100% 100%, 0 100%);
} */

/* effect 5*/

.mask_type1-a2 {
  --maskAnimationDuration: 0.5s;
}

.mask_type1 {
  --maskClipPath: polygon(
    0 0,
    90% 0,
    100% 0,
    100% 90%,
    100% 100%,
    10% 100%,
    0 100%,
    0 10%
  );
}

/* effect 6*/

.mask_type2-a2 {
  --maskAnimationDuration: 0.9s;
  --maskAnimationTimingFunction: cubic-bezier(1, 0.18, 0, 1.14);
}

/*
=====
SETTINGS
=====
*/

.mask {
  --maskStrokeColor: #ecebef;
  --maskStrokeThickness: 5px;
}

/*
=====
DEMO
=====
*/

.image_manager {
  box-sizing: border-box;
  width: 100%;
  padding: 1.5rem;
  margin: auto;
  text-align: center;
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: repeat(auto-fit, 15rem);
  justify-content: center;
  position: relative;
}

.image_manager .image_container {
  border: dotted 1px #2f3349 !important;
  border-radius: 20px;
  transition: all ease 3s;
}

.image_container {
  padding: 20px 0px 450px;
}
.icon-team span {
  padding: 0px 5px;
}
.image_container .information {
  display: flex;
  justify-content: center;
  padding: 15px 2px 0px 20px;
  /* position: absolute;
  bottom: calc(50% - 65px);
  left: calc(50% - 35px); */
  z-index: -1;
  transition: all ease 0.75s;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.image_container .information .iconify {
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin: 0px 5px;
}

.image_title {
  font-size: 1rem;
  margin-top: 6px;
  font-weight: 700;
  display: block;
  text-transform: capitalize;
}

.image_subtitle {
  font-size: 1rem;
  margin-top: 3px;
  font-weight: 400;
  display: block;
  text-transform: capitalize;
}
.information p {
  margin: 0 0 12px;
}
